.main {
  .menu {
    padding-top: 30px;
    background-color: #26190c;
    overflow: hidden;
    flex-basis: 0;
    flex-grow: 0;
    flex-shrink: 0;
    display: block;
    transition: flex-basis 300ms ease-in-out;

    &.active {
      flex-basis: 200px;
    }

    h4 {
      color: #fff;
      padding: 10px 20px;
      font-weight: 400;
    }

    a.logout {
      margin-top: 20px;
      background-color: #d98d37;
      display: block;
      text-decoration: none;
      line-height: 40px;
      color: #fff;
      padding-left: 40px;
    }

    ul {
      list-style: none;
      padding: 0;
      min-width: 200px;

      li.item {
        color: #fff;
        line-height: 44px;
        box-sizing: border-box;
        border-left: 3px solid transparent;
        cursor: pointer;
        transition: background-color 300ms ease-in-out 1ms;

        span {
          display: grid;
          grid-template-columns: 40px auto 30px;
          padding: 3px 10px 3px 25px;

          img:first-child {
            height: 14px;
            margin: 14px auto;
            display: block;
          }
        }

        ul {
          height: auto;
          max-height: 0;
          transition: max-height 300ms ease-in-out 1ms;
          overflow: hidden;
          background-color: #221509;
          padding-left: 30px;

          li {
            a {
              display: grid;
              grid-template-columns: 20px auto;
              line-height: 30px;
              font-size: 14px;
              padding: 10px;
              color: #fff;
              text-decoration: none;

              img {
                margin-top: 11px;
                height: 8px;
              }
            }

            a:hover {
              color: #d98d37;
            }
          }
        }

        &.active {
          background-color: #fff;
          color: #26190c;
          border-left-color: #d98d37;
        }

        &.active img {
          animation: arrow 300ms ease-in-out 0s forwards;
        }

        @keyframes arrow {
          from {
            transform: rotate(0deg);
          }
          to {
            transform: rotate(90deg);
          }
        }

        &.active ul {
          max-height: 600px;
        }
      }
    }
  }
  @media (min-width: 700px) {
    .menu {
      flex-basis: 250px;
      &.active {
        flex-basis: 250px;
      }
    }
  }
}

.progress-bar {
    border-radius: 3px;
    position: relative;
    box-sizing: border-box;
    height: 30px;
    background-color: #eee;

    > div {
        text-align: center;
        color: #fff;
        position: absolute;
        border-radius: 3px;
        background-color: #1cc88a;
        top: 0;
        bottom: 0;
        left: 0;
        width: 0;
        font-weight: 600;
        line-height: 30px;
        display: block;
        height: 30px;
    }
}
.unsecured {
  .account-access {
    background: #fff;
    padding: 20px;

    form {
      padding: 20px;

      h4.message {
        color: #721c24;
        background-color: #f8d7da;
        border: 1px solid #f5c6cb;
        line-height: 30px;
        text-align: center;
        font-weight: 400;
        margin-bottom: 5px;
      }
      .form-input {
        margin-bottom: 10px;

        input,
        label {
          display: block;
        }
        input {
          border: 1px solid #bdc4c9;
          font-size: 16px;
          padding: 5px 6px;
          width: 100%;
          line-height: 22px;
          border-radius: 3px;

          &.error {
            border: 1px solid #c02;
          }
        }
        input:focus {
          outline: none;
        }
      }
      div.actions {
        display: flex;
        flex-direction: row;
        justify-content: space-around;

        button {
          color: #fff;
          border: 0;
          transition: 0.3s;
          line-height: 30px;
          font-weight: 400;
          width: 90px;
          background: #d98d37;
          border-radius: 3px;
          font-size: 16px;
          cursor: pointer;
        }

        button:focus {
          outline: none;
        }

        label {
          font-size: 14px;
          a {
            text-decoration: none;
            color: #005ff8;
          }
          input {
            margin-right: 5px;
          }
        }
      }
    }

    .top {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding-bottom: 10px;
      border-bottom: 1px solid #bdc4c9;

      span:last-child {
        b {
          color: #005ff8;
          font-weight: normal;
          cursor: pointer;
        }
      }
    }
  }
}

.meetings {
    .scheduler {
        .schedule {
            h5.message {
                color: red;
                text-align: center;
                font-weight: normal;
            }
            .row-checkboxes {
                grid-column: 5 / 12;
            }
            button.btn {
                border: 0;
            }
            .start-time {
                display: flex;
                flex-direction: row;
                > div {
                    width: 100%;
                }
            }
          }
    }
}
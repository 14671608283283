.secured {
  display: flex;
  flex-direction: column;
  .footer {
    grid-row: 3;
    background-color: #fff;
    padding: 25px;

    .copyright {
      text-align: center;
      font-size: 13px;
    }
  }
}

body {
  background: #eaf0f3;
}
.unsecured {
  header {
    height: 150px;
  }
  .forms {
    display: grid;
    grid-template-columns: auto;
    max-width: 900px;
    margin: 0 auto;
    min-height: 424px;
    box-shadow: 2px 3px 10px -7px rgba(0, 0, 0, 0.5);
  }
  .quote {
    display: grid;
    background: #1c272b;
    padding: 20px;
    display: grid;
    grid-template-columns: auto;
    grid-template-columns: repeat(2, 1fr);
    color: #fff;

    div:first-child {
      display: grid;
      grid-template-columns: auto;
      justify-content: space-around;
      p {
        span {
          display: block;
        }
      }
      strong {
        align-self: end;
      }
    }

    div:last-child {
      position: relative;
      justify-self: center;
      img:first-child {
        width: 100%;
        max-width: 250px;
        animation: world infinite 20s linear;
      }

      img:last-child {
        position: absolute;
        left: 20px;
        bottom: 0;
        transform: rotate(45deg);
        animation: plane infinite 2s linear;
      }
      @keyframes world {
        from {
          transform: rotate(0deg);
        }
        to {
          transform: rotate(360deg);
        }
      }
      @keyframes plane {
        0% {
          left: 20px;
          bottom: 20px;
          opacity: 1;
          transform: rotate(45deg);
        }
        40% {
          left: 100px;
          bottom: 20px;
          opacity: 1;
          transform: rotate(35deg);
        }
        70% {
          left: 140px;
          bottom: 100px;
          opacity: 1;
          transform: rotate(-30deg);
        }
        100% {
          left: 140px;
          bottom: 220px;
          opacity: 0;
          transform: rotate(-30deg);
        }
      }
    }
  }
  @media (min-width: 700px) {
    .forms {
      grid-template-columns: 4fr 3fr;
    }

    .quote {
      grid-template-columns: auto;
      div:first-child {
        justify-self: center;
        align-self: center;
        display: block;
        p {
          span {
            display: inline;
          }
        }
      }
    }
  }
}

.newsletters {
  padding: 15px 0;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 20px;
  .newsletter {
    border-left: 3px solid #26190c;
    background: #fff;
    padding: 15px;
    border-radius: 10px;
    color: #37474f;
    box-shadow: 0 1px 2px #37474f;
    max-width: 450px;
    transition: all 300ms ease-in-out;
    cursor: pointer;
    text-decoration: none;
    h4 {
      font-weight: 400;
      color: #26190c;
      margin-bottom: 10px;
    }
    p {
      margin-bottom: 10px;
    }
    p.actions a {
      color: #fff;
      display: block;
      padding: 3px 8px;
      background-color: #d98d37;
      text-decoration: none;
      border-radius: 3px;
      font-size: 14px;
    }
    p.actions a:hover {
      background-color: #26190c;
      color: #fff;
    }
    p.actions {
      display: grid;
      justify-content: start;
    }
    div.summary {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      align-content: center;
      align-items: center;
      p {
        text-align: center;
        font-size: 14px;
        span {
          width: 100px;
          margin: 3px auto auto auto;
          line-height: 30px;
          border-radius: 5px;
          color: #fff;
          display: block;
          font-size: 16px;
        }
      }
      p:first-child > span {
        background-color: #4e73df;
      }
      p:nth-child(2) > span {
        background-color: #1cc88a;
      }
      p:nth-child(3) > span {
        background-color: #b97c0a;
      }
      p:last-child > span{
        background-color: #e74a3b;
        color: #fff;
      }
    }
  }
}
@media (min-width: 1200px) {
  .newsletters {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (min-width: 1800px) {
  .newsletters {
    grid-template-columns: repeat(3, 1fr);
  }
}

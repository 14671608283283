.eastcoin {
    .uploaded {
        table {
            thead.table-dark {
                background-color: #26190c !important;
            }
            button:focus {
                outline: none;
            }
            button, strong {
                height: 30px;
                width: 64px;
                display: block;

                span {
                    width: 0%;
                    display: block;
                    height: 30px;
                    animation: span-animate 800ms ease-in-out 0ms infinite normal;
                }
                @keyframes span-animate {
                    0% {
                        width: 0%;
                        background-color: #fff;
                    }
                    100% {
                        width: 100%;
                        background-color: #454d55;
                    }
                  }
            }
        }
    }
}
.preview {
  .print-area {
    background-color: #fff;
    border: 1px solid #bbbbbc;
    max-width: 900px;
    margin: 0 auto;
    padding: 40px;
    grid-column-gap: 20px;
    display: grid;

    h2 {
      text-align: center;
      font-weight: 600;
      line-height: 40px;
      margin-bottom: 20px;
    }

    h4 {
      font-weight: 600;
    }

    .field {
      display: flex;
      flex-direction: row;
      align-content: space-around;
      margin-top: 5px;

      > label {
        width: 220px;
        min-width: 220px;
      }
      label {
        font-weight: 500;
      }
      img {
        display: block;
        width: 250px;
        margin: 20px auto;
        border-radius: 5px;
      }
    }
  }
  @media (min-width: 900px) {
    .print-area {
      grid-template-columns: repeat(2, 1fr);
      h2,
      h4,
      .span-2 {
        grid-column: span 2;
      }
    }
  }
  .actions {
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    button,
    a,
    span.btn-wait {
      padding: 5px 10px;
      line-height: 25px;
      border-radius: 4px;
      cursor: pointer;
      margin: 0 10px;
      display: block;
      background-color: #d98d37;
      border: 0;
      color: #fff;
      text-decoration: none;
      max-width: 130px;
    }
    button {
      background-color: #d98d37;
      border: 0;
      color: #fff;
    }
  }
}

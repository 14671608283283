header {
  .brand {
    background-color: #26190c;
    height: 70px;
    padding: 5px;
    box-sizing: border-box;
    flex-basis: 200px;
    flex-grow: 0;
    flex-shrink: 0;

    > div {
      display: flex;
      img {
        display: block;
        height: 55px;
        margin: auto;
      }
      .toggler {
        width: 30px;
        padding: 10px;
        display: flex;
        align-self: center;
        flex-direction: column;
        cursor: pointer;

        span {
          height: 1px;
          display: block;
          background-color: #fff;
          margin: 4px 0;
        }
      }
    }
  }
  @media (min-width: 700px) {
    .brand {
      flex-basis: 250px;
      > div {
        .toggler {
          display: none;
        }
      }
    }
  }
}

.videos {
    .uploader {
        .upload {
            .row-checkboxes {
                grid-column: 5 / 12;
            }
            button.btn {
                border: 0;
            }
          }
    }
}
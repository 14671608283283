* {
  margin: 0;
}
body {
  font-size: 16px;
  font-family: "Poppins", sans-serif;
  color: #4c4c4c;
}
span.btn-wait {
  display: block;
  padding: 10px 0;
  width: 100%;
  position: relative;
  margin: 0 auto;
}
span.btn-wait:after {
  content: "";
  position: absolute;
  background-color: #fff;
  height: 3px;
  width: 20px;
  left: -20px;
  animation: btn-wait-animate 800ms ease-in-out 0ms infinite normal;
}
a.btn,
button.btn {
  border-radius: 2px;
  text-transform: capitalize;
  font-size: 15px;
  padding: 10px 19px;
  text-decoration: none;
  background-color: #4099ff;
  border-color: #4099ff;
  color: #fff;
  font-weight: 400;
  cursor: pointer;
  display: inline-block;
}
button.btn {
  margin: 0 auto;
  display: block;
  padding: 7px 15px;
}
@keyframes btn-wait-animate {
  0% {
    left: -20px;
  }
  50% {
    left: 100%;
  }
  100% {
    left: -20px;
  }
}
.main {
  .card {
    border-radius: 5px;
    -webkit-box-shadow: 0 0 5px 0 rgba(43, 43, 43, 0.1), 0 11px 6px -7px rgba(43, 43, 43, 0.1);
    box-shadow: 0 0 5px 0 rgba(43, 43, 43, 0.1), 0 11px 6px -7px rgba(43, 43, 43, 0.1);
    border: none;
    margin: 20px 0;
    background: #fff;
    padding: 20px;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;

    h4 {
      font-weight: 400;
      position: relative;
      margin-bottom: 30px;
    }

    h4:after {
      content: "";
      background-color: #d2d2d2;
      width: 60px;
      height: 1px;
      position: absolute;
      bottom: -15px;
      left: 0;
    }
    form {
      .actions {
        display: flex;
        flex-direction: row;
        justify-content: center;
        button,
        a,
        span.btn-wait {
          padding: 5px 10px;
          line-height: 25px;
          border-radius: 4px;
          cursor: pointer;
          margin: 0 10px;
          display: block;
          background-color: #d98d37;
          border: 0;
          color: #fff;
          text-decoration: none;
          max-width: 130px;
        }
        button {
          background-color: #d98d37;
          border: 0;
          color: #fff;
        }
      }
    }
  }
  .inputs {
    .editor {
      margin: 15px 0;
      max-width: 700px;
      .ck-editor__editable_inline {
        min-height: 200px;
        width: 100%;
      }
      h5 {
        font-weight: 400;
        font-size: 16px;
        line-height: 35px;
      }
    }
    h5.message {
      text-align: center;
      color: red;
      font-weight: 400;
      margin-bottom: 10px;
    }
    .row {
      margin-bottom: 18px;
      display: grid;
      grid-gap: 10px;
      grid-template-columns: 2fr 3fr;

      .form-label {
        font-weight: 300;
        text-align: right;
        line-height: 35px;
        padding: 0 10px;
        box-sizing: border-box;
        font-size: 14px;
      }
      .form-input {
        input,
        textarea {
          display: block;
          padding: 8px;
          box-sizing: border-box;
          width: 100%;
          font-size: 14px;
          border-radius: 2px;
          border: 1px solid #ccc;
          transition: all 200ms ease-in;
        }
        textarea {
          min-height: 200px;
        }
        input:focus,
        textarea:focus {
          outline: none;
          border: 1px solid #007bff;
        }
      }
    }
    .row, .editor {
      span.error {
        color: red;
        line-height: 25px;
        font-size: 15px;
      }
    }
  }
  .dialog .delete {
    padding: 20px 10px;
    p {
      text-align: center;
      color: red;
    }
  }
  .content-block {
    display: block;
    padding: 20px;
  }
  @media (min-width: 700px) {
    .inputs {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      .editor {
        grid-column: span 2;
      }
    }
    form {
      max-width: 1000px;
    }
  }
}

.create-newsletter {
    .group-test {
        margin: 15px auto;
        border: 1px solid saddlebrown;
        width: 300px;

        input {
            padding: 10px;
            border: 0;
            width: 200px;
            box-sizing: border-box;
        }

        span {
            display: inline-block;
            background-color: saddlebrown;
            line-height: 34px;
            width: 100px;
            text-align: center;
            color: #fff;
            font-size: 15px;
            cursor: pointer;
        }
    }
}
.all-applications {
  padding: 15px 0;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 20px;

  .application {
    border-left: 3px solid #26190c;
    background: #fff;
    padding: 15px;
    border-radius: 10px;
    color: #37474f;
    box-shadow: 0 1px 2px #37474f;
    max-width: 450px;
    transition: all 300ms ease-in-out;
    cursor: pointer;
    text-decoration: none;
    display: grid;
    grid-column-gap: 10px;
    grid-template-columns: 100px auto;
    align-items: center;

    img {
      height: 100px;
      width: 100px;
      border-radius: 50px;
    }

    h4 {
      font-weight: 400;
      color: #26190c;
      margin-bottom: 10px;

    }
    p {
      margin-bottom: 10px;
    }
    p.actions {
        display: grid;
        justify-content: start;

        a {
            color: #fff;
            display: block;
            padding: 3px 8px;
            background-color: #d98d37;
            text-decoration: none;
            border-radius: 3px;
            font-size: 14px;
        }
        a:hover {
            background-color: #26190c;
            color: #fff;
        }
    }
  }
}
@media(min-width: 700px){
  .all-applications {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media(min-width: 1200px){
  .all-applications {
    grid-template-columns: repeat(4, 1fr);
  }
}
